import React from "react"
import { Router } from "@reach/router"

import Orders from "./orders"
import Order from "./order"
import Profile from "./profile"

const App = () => {
  return (
    <Router basepath="/user">
      <Orders path="/orders" />
      <Order path="/order/:orderId" />
      <Profile path="/profile" />
    </Router>
  )
}

export default App
